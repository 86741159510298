<script setup>
import { inject, ref } from "vue";
import { Form, Field } from "vee-validate";
import { onMounted, onUnmounted, reactive } from "vue";
import * as yup from "yup";
import useApi from "../utils/useApi";
import { useRouter, useRoute } from "vue-router";
import { url } from "@/config";

const iframeUrl = import.meta.env.VITE_TEMPLATE_BUILDER;

const api = useApi();
const router = useRouter();
const route = useRoute();
const startLoading = inject("startLoading");
const stopLoading = inject("stopLoading");
const templateDetail = ref({});

const isDesign = ref(false);

const form = reactive({
  name: "",
  subject: "",
  category: "",
});

const getTemplateDetail = async () => {
  const templateId = route.params.templateId;
  const response = await api.getTemplateDetail(templateId);
  if (response.success) {
    form.name = response.data.name;
    form.subject = response.data.subject;
    form.category = response.data.category;
    templateDetail.value = response.data;
  }
};

const onReceiveContent = async (e) => {
  if (e.data.type === "content") {
    /**
     * Execute submit here
     */
    startLoading();
    const response = await api.createTemplate({
      name: form.name,
      subject: form.subject,
      body: !isDesign.value
        ? null
        : e.data.payload.html
            .replaceAll("<body", '<div class="body-replace"')
            .replaceAll("</body>", "</div>")
            .replaceAll("body {", ".body-replace {"),
      style: !isDesign.value ? null : JSON.stringify(e.data.payload.design),
      source: "email",
      category: form.category,
      type: "MY_TEMPLATE",
      params: 0,
      params_value: {},
    });
    stopLoading();
    if (response.success) {
      router.replace({
        name: "campaign.template.email",
      });
    }
  }
};

onMounted(() => {
  window.addEventListener("message", onReceiveContent);
});

onUnmounted(() => {
  window.removeEventListener("message", onReceiveContent);
});

const onBack = () => {
  if (isDesign.value) {
    isDesign.value = false;
  } else {
    router.back();
  }
};

const onIframeLoaded = () => {
  const iframe = document.getElementById("iframe-editor");
  const message = {
    type: "load",
    payload: {
      credentials: localStorage.getItem("user"),
      targetUrl: `${url.api}/upload-file`,
      html: templateDetail.value.body,
      design: templateDetail.value.style,
    },
  };
  iframe.contentWindow.postMessage(message, "*");
};

const onSubmit = () => {
  // onReceiveContent({ data: { type: "content" } });
  isDesign.value = true;
};

const onSaveDesign = () => {
  const iframe = document.getElementById("iframe-editor");
  if (iframe) {
    const message = {
      type: "save",
    };

    iframe.contentWindow.postMessage(message, "*");
  }
};

const schema = yup.object().shape({
  name: yup.string().required().label("Name"),
  subject: yup.string().required().label("Subject"),
  editorText: yup.string().required().label("HTML"),
  category: yup.string().required().label("Category"),
});

onMounted(() => {
  getTemplateDetail();
});
</script>

<template>
  <div class="container-fluid mt-2">
    <div class="row g-2">
      <div class="col-xl-12">
        <div class="card mb-0" style="height: 100%">
          <div class="d-flex align-items-center justify-content-between">
            <div class="card-header d-flex align-items-center border-0">
              <i class="ri-article-line me-2 fs-18"></i>
              <h5 class="mb-0 p-0">
                {{ isDesign ? "Design" : "Template Detail" }}
              </h5>
            </div>
            <button @click="onBack" class="me-4 d-flex align-items-center back">
              <i class="ri-arrow-left-s-line me-2 text-muted fs-16"></i>
              <span class="text-muted">
                {{ isDesign ? "Template Detail" : "Template List" }}
              </span>
            </button>
          </div>
          <BaseButton
            type="button"
            v-if="isDesign"
            @click="onSaveDesign"
            class="mt-3"
            :variant="'primary'"
            style="width: 120px; margin-left: 16px"
          >
            Save Design
          </BaseButton>
          <div class="card-body border-0" style="overflow: hidden !important">
            <div v-if="!isDesign" style="padding: 100px 200px">
              <div
                style="margin-bottom: 50px"
                class="d-flex align-items-center justify-content-center"
              >
                <h6 :class="[isDesign && 'text-muted']">Template Detail</h6>
                <i class="ri-arrow-right-s-line fs-18 ms-2 me-2"></i>
                <h6
                  @click="isDesign = true"
                  :class="[!isDesign && 'text-muted']"
                >
                  Design
                </h6>
              </div>
              <Form :validation-schema="schema" autocomplete="off">
                <div class="row">
                  <div class="mb-2">
                    <BaseLabel text="Template Name" required size="14px" />
                    <Field
                      type="text"
                      class="form-control"
                      id="key"
                      v-model="form.name"
                      placeholder="Template name"
                      name="form.name"
                    />
                  </div>
                  <div class="mb-2">
                    <BaseLabel text="Subject" required size="14px" />
                    <Field
                      type="text"
                      class="form-control"
                      id="key"
                      v-model="form.subject"
                      placeholder="Subject"
                      name="form.subject"
                    />
                  </div>
                  <div class="mb-2">
                    <BaseLabel text="Category" required size="14px" />
                    <Field
                      type="text"
                      class="form-control"
                      id="key"
                      v-model="form.category"
                      placeholder="Category"
                      name="form.category"
                    />
                  </div>
                  <div class="col-12">
                    <BaseButton
                      type="button"
                      @click="onSubmit"
                      class="mt-3 w-100"
                      :variant="'primary'"
                    >
                      Go to design
                    </BaseButton>
                  </div>
                </div>
              </Form>
            </div>
            <iframe
              v-else
              id="iframe-editor"
              @load="onIframeLoaded"
              :src="iframeUrl"
              width="100%"
              style="height: 100vh"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-10">
        
      </div> -->
    </div>
  </div>
</template>

<style scoped>
h6 {
  margin-bottom: 0;
  cursor: pointer;
}

.back {
  background-color: transparent;
  border: none;
}
</style>

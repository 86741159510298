export const getUrl = (endpoint) => {
  const hashedUser = JSON.parse(localStorage.getItem("user")).id;
  return `${endpoint}/${hashedUser}`;
};
export const getUniqueArray = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};
export const debounce = (func, wait, immediate) => {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export function isColorLight(hexColor) {
  if (!hexColor) {
    return hexColor;
  }
  hexColor = hexColor.replace("#", "");

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 128;
}

export function jsonToCsv(objArray) {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  const headers = Object.keys(array[0]);
  str += headers.join(";") + "\r\n";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let j = 0; j < headers.length; j++) {
      line += array[i][headers[j]];
      if (j < headers.length - 1) line += ";";
    }
    str += line + "\r\n";
  }
  return str;
}

export const downloadCSV = (data, filename) => {
  const csvData = jsonToCsv(data);

  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // For IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
